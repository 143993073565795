.weekly {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--lightgreen-color);
    font-family: Raleway, sans-serif;
    gap: 0.75rem;
    /* margin: 0 0 0.5rem 0; */
    text-align: start;
}

.weekly_title_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.75rem;
    width: 20%;
}

.weekly_content_container {
    width: 80%;
}

.weekly_container1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.75rem;
    background-color: var(--white-color);
    padding: 0 0.25rem;
}

.weekly_container2 {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.75rem;
    background-color: var(--white-color);
    padding: 0 0.25rem;
}

.weekly_title {
    justify-content: start;
    align-items: start;
    text-transform: capitalize;
    font-family: "Trebuchet MS", Raleway, sans-serif;
    font-weight: 1000;
    font-size: clamp(1.2rem, 2.5vw, 1.6rem);
    color: var(--blue-color);
    /* text-decoration: underline double;
    text-underline-offset: 0.35rem; */
    margin: 0.25rem 0;
    padding: 0.5rem;
}

.weekly_description {
    text-align: start;
    padding: 0.5rem;
}

.weekly_photo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 0.5rem;
}

.weekly_content {
    width: 50%;
}

.weekly_name {
    font-weight: 600;
    font-size: clamp(1.1rem, 2.5vw, 1.4rem);
    color: var(--blue-color);
    padding: 0.5rem;
}

.weekly_timeloc {
    font-weight: 600;
    font-size: clamp(1rem, 2.5vw, 1.1rem);
    color: var(--blue-color);
    padding: 0.5rem;
}

.weekly_selfintro {
    padding: 0.5rem;
    line-height: 125%;
}

.weekly_link {
    text-decoration: underline;
}

@media (max-width: 55rem) {
    .weekly_selfintro {
        line-height: 110%;
    }
}

@media (max-width: 45rem) {
    .weekly {
        flex-direction: column;
    }

    .weekly_selfintro {
        line-height: 100%;
    }

    .weekly_container1,
    .weekly_container2 {
        flex-direction: column;
        align-items: center;
        gap: 0;
    }

    .weekly_title_container {
        width: 100%;
    }

    .weekly_content_container {
        width: 100%;
    }

    .weekly_content {
        width: 100%;
    }

    .weekly_photo {
        width: 100%;
    }

    .weekly_content_activitiescontent {
        display: none;
    }
}