.numbers {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 0.5rem;
    background: var(--lightblue-color);
    padding: 1rem 0.5rem;
    box-sizing: border-box;
}

.numbers-title {
    text-transform: capitalize;
    font-family: Raleway, sans-serif;
    font-weight: 600;
    font-size: clamp(1.1rem, 2.5vw, 1.4rem);
    color: var(--blue-color);
    text-decoration: underline;
    white-space: nowrap;
}

.numbers-buttons-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr;
    justify-content: space-between;
    align-items: start;
    gap: 0.5rem;
    width: 100%;
}

.numbers-button {
    grid-column: span(1);
    grid-row: span(1);
}

.numbers-blue-button {
    background: var(--blue-color);
    color: var(--white-color);
    border: 1px solid var(--blue-color);

    margin: 0.1rem;
    padding: 0.5rem 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-family: Raleway, sans-serif;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: clamp(1.1rem, 2vw, 1.4rem);
    width: auto;
    white-space: nowrap;
}

.numbers-button p {
    font-family: Raleway, sans-serif;
    text-align: start;
    margin: 0.2rem;
}

@media (max-width: 59rem) {
    .numbers {
        flex-direction: column;
    }

    .numbers-buttons-container {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        width: 100%;
    }
}

/* @media (max-width: 45rem) {
    .numbers {
        flex-direction: column;
    }

    .numbers-buttons-container {
        grid-template-columns: 1fr 4fr 1fr;
        grid-template-rows: repeat(4, 1fr);
        width: 100%;
    }

    .numbers-button {
        grid-column: 2 / 3;
        grid-row: span(1);
    }
} */