.service {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 0.5rem;
    margin: 0;
    box-sizing: border-box;
}

.service-title {
    text-transform: capitalize;
    font-family: "Trebuchet MS", Raleway, sans-serif;
    font-weight: 1000;
    font-size: clamp(1.2rem, 2.5vw, 1.6rem);
    color: var(--blue-color);
    /* text-decoration: underline double;
    text-underline-offset: 0.35rem; */
    margin: 0.5rem 0;
    text-align: center;
    width: 100%
}

.service-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 0 0.5rem 0;
}

.service-text-style-1 {
    display: flex;
    flex-direction: column;
    background-color: var(--white-color);
    width: 100%;
    padding: 0.25rem 0.5rem;
    box-sizing: border-box;
}

.service-text-style-2 {
    display: flex;
    flex-direction: column;
    background-color: var(--lightgreen-color);
    width: 100%;
    padding: 0.25rem 0.5rem;
    box-sizing: border-box;
}

.service-text {
    width: 100%;
    display: grid;
    grid-template-columns: 3rem 9rem 16rem auto;
    gap: 0.5rem;
    text-align: start;
    padding: 0.25rem;
}

.service a {
    color: var(--blue-color);
    text-decoration: underline;
} 

@media (max-width: 45rem) {
    .service-text {
        grid-template: 
            '3rem auto'
            '8rem auto';
    }
}