.specialolympics {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    box-sizing: border-box;
    background: var(--white-color);
    font-family: Raleway, sans-serif;
    gap: 1rem;
    margin: 0.5rem 0;

}

.specialolympics_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.specialolympics_container p {
    text-align: start;
    line-height: 135%;
}

.specialolympics_container li {
    text-align: start;
    line-height: 135%;
    padding: 0.25rem 0;
}

.specialolympics_container img {
    max-width: 100%;
    height: auto;
}

.specialolympics_container a {
    text-decoration: underline;
}

.specialolympics_title {
    justify-content: start;
    align-items: start;
    text-align: start;
    text-transform: capitalize;
    font-family: "Trebuchet MS", Raleway, sans-serif;
    font-weight: 1000;
    font-size: clamp(1.2rem, 2.5vw, 1.6rem);
    color: var(--blue-color);
    /* text-decoration: underline double;
    text-underline-offset: 0.35rem; */
}

@media (max-width: 45rem) {
    .specialolympics {
        flex-direction: column;
        align-items: center;
    }

    .specialolympics_container p {
        line-height: 125%;
    }
}