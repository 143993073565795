.specialnews {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    gap: 0;
    background: var(--black-color);
    padding: 0.5rem;
    box-sizing: border-box;
}

.specialnews2a {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    gap: 0;
    background: #b2e0bb;
    padding: 0;
    box-sizing: border-box;
}

.specialnews2b {
    display: none;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    gap: 0;
    background: #b2e0bb;
    padding: 0;
    box-sizing: border-box;
}

.specialnews3a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 0;
    /* background: #fffcf7; */
    padding: 0rem;
    box-sizing: border-box;
}

.specialnews3b {
    display: none;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    gap: 0;
    /* background: #fffcf7; */
    padding: 0rem;
    box-sizing: border-box;
}

.specialnews4b {
    display: none;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    gap: 0;
    background: #fffcf7;
    padding: 0rem;
    box-sizing: border-box;
}

.specialnews-title {
    text-transform: capitalize;
    font-family: Raleway, sans-serif;
    font-weight: 600;
    font-size: clamp(1.1rem, 2.5vw, 1.4rem);
    color: var(--white-color);
    white-space: nowrap;
    margin: 0.5rem 0.25rem;
}

.specialnews-title2 {
    text-transform: capitalize;
    font-family: Raleway, sans-serif;
    font-weight: 600;
    font-size: clamp(1.1rem, 2.5vw, 1.4rem);
    color: var(--white-color);
    white-space: nowrap;
    margin: 0 0 0 0.25rem;
}

.specialnews-button {
    grid-column: span(1);
    grid-row: span(1);
}

.specialnews-so {
    border: 0;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-family: Raleway, sans-serif;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: clamp(1.1rem, 2vw, 1.4rem);
    width: auto;
    white-space: nowrap;
}

.specialnews-button p {
    font-family: Raleway, sans-serif;
    text-align: start;
    margin: 0.2rem;
}

@media (max-width: 59rem) {
    .specialnews {
        flex-direction: column;
    }
}

@media (max-width: 45rem) {
    .specialnews2a {
        display: none;
    }

    .specialnews2b {
        display: flex;
    }
    
    .specialnews3a {
        display: none;
    }

    .specialnews3b {
        display: flex;
    }

    .specialnews4b {
        display: flex;
    }

}

.specialnewslist {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 0.5rem;
    background-color: var(--lightblue-color);
    padding: 0.75rem 0.5rem;
    box-sizing: border-box;
}

.specialnewslist-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.specialnewslist-title {
    text-transform: capitalize;
    font-family: "Trebuchet MS", Raleway, sans-serif;
    font-weight: 1000;
    font-size: clamp(1.2rem, 2.5vw, 1.6rem);
    color: var(--blue-color);
    /* text-decoration: underline double;
    text-underline-offset: 0.35rem; */
    white-space: nowrap;
    padding: 0 1rem 0.25rem 0.35rem;
}

.specialnewslist-container {
    display: grid;
    /* grid-template-columns: repeat(4, 1fr); */
    grid-template-columns: 1fr 2fr 2fr 1fr;
    justify-content: space-between;
    align-items: center;
    gap: 0.35rem;
    width: 100%;
}

.specialnewslist-cell {
    grid-column: span(1);
    grid-row: span(1);
    text-align: start;
    padding: 0;
    margin: 0;
    height: 100%;
    border-radius: 5px;
    border: 1px solid var(--mediumblue-color);
    background: var(--lightblue-color);
}

.specialnewslist-cell-empty {
    grid-column: span(1);
    grid-row: span(1);
    text-align: start;
    padding: 0;
    margin: 0;
    height: 100%;
    border-radius: 5px;
    border: 1px solid var(--mediumblue-color);
    background: var(--lightblue-color);
}

.specialnewslist-button-date {
    border: 0;
    text-align: center;
    font-weight: 600;
    font-size: clamp(1.0rem, 2.5vw, 1.1rem);
    margin: 0.35rem 0.35rem 0 0.35rem;
    padding: 0.25rem;
    border-radius: 5px;
    background-color: var(--white-color);
    width: auto;
}

.specialnewslist-button-title {
    border: 0;
    text-align: center;
    font-weight: 600;
    font-size: clamp(1.0rem, 2.5vw, 1.1rem);
    margin: 0.35rem 0.35rem 0 0.35rem;
    padding: 0.25rem;
    width: auto;
}

.specialnewslist-button {
    border: 0;
    margin: 0.35rem 0.35rem 0 0.35rem;
    padding: 0;
    width: auto;
}

.specialnewslist-button img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

@media (max-width: 45rem) {
    .specialnewslist-cell-empty {
        display: none; 
    }
    .specialnewslist-container {
        grid-template-columns: 1fr 1fr;
        /* grid-template-rows: 1fr 1fr 1fr; */
        width: 100%;
    }

}