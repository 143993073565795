@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

body {
  margin: 0;
  font-family: Raleway, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--lightergrey-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --white-color:#ffffff;
  --green-color: #A5C727;
  --blue-color: #005D8B;
  --darkgrey-color: #333333;
  --grey-color: #666666;
  --black-color: #000000;
  --lightgreen-color: #e8f4be;
  --lightblue-color: #d0e5f1;
  --lightgrey-color: #999999;
  --lightergrey-color: #dddddd;
  --lightestgrey-color: #ebebeb;
  --white-color-semitransparent: #ffffffee;
  --transparent-color: #ffffff00;
  --mediumblue-color: #4d94b8;
}
