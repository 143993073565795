.pastevents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 0.5rem;
    margin: 0;
    box-sizing: border-box;
}

.pastevents-title {
    text-transform: capitalize;
    font-family: "Trebuchet MS", Raleway, sans-serif;
    font-weight: 1000;
    font-size: clamp(1.2rem, 2.5vw, 1.6rem);
    color: var(--blue-color);
    /* text-decoration: underline double;
    text-underline-offset: 0.35rem; */
    white-space: nowrap;
    margin: 0.5rem 0;
    text-align: start;
    padding: 0 0.5rem;
}

.pastevents-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.pastevents-text-style-1 {
    display: flex;
    flex-direction: row;
    background-color: var(--white-color);
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    gap: 0.5rem;
}

.pastevents-text-style-2 {
    display: flex;
    flex-direction: row-reverse;
    background-color: var(--lightgreen-color);
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    gap: 0.5rem;
}

.pastevents-text {
    width: 50%;
}

.pastevents-text p {
    font-family: Raleway, sans-serif;
    text-align: start;
    margin: 0.5rem;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
}

.pastevents-slider {
    min-height: 200px;
    min-width: 200px;
    width: 50%;
    display: flex;
    justify-content: center;
}

.pastevents-slider img {
    max-height: 500px;
    max-width: 100%;
    display: block;
}

@media (max-width: 45rem) {
    .pastevents-text-style-1,
    .pastevents-text-style-2 {
        flex-direction: column;
    }

    .pastevents-text,
    .pastevents-slider {
        width: 100%;
    }
}