.login {
    font-family: Raleway, sans-serif;
    margin: 0 0 0.75rem 0;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-title {
    text-transform: capitalize;
    font-family: "Trebuchet MS", Raleway, sans-serif;
    font-weight: 1000;
    font-size: clamp(1.2rem, 2.5vw, 1.6rem);
    color: var(--blue-color);
    /* text-decoration: underline double;
    text-underline-offset: 0.35rem; */
    padding: 1rem 0 0.5rem 0;
}

.login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem 0 0 0;
    max-width: fit-content;
}

.login-form {
    display: grid;
    grid-template-columns: 7rem auto;
    max-width: fit-content;
    align-items: center;
}

.login-content p {
    margin: 0.5rem 0 1rem 0.5rem;
    text-align: start;    
}

.login-content input {
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 5px;
    border: 1px solid gray;
    font-family: Raleway, sans-serif;
    width: 12rem;
}

.login-content label {
    font-weight: 600;
    text-align: start;
    padding: 0.5rem;
    margin: 0.5rem 0;
}

.login-content span {
    padding: 0.5rem;
    color: var(--darkgrey-color);
    font-size: clamp(0.8rem, 2vw, 0.9rem);
    text-align: start;
}

.login-content button {
    cursor: pointer;
    margin: 0.25rem 0;
    padding: 0.25rem 0.75rem;
    border-radius: 5px;
    color: var(--black-color);
    font-family: Raleway, sans-serif;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: clamp(1.1rem, 2.5vw, 1.4rem);
    white-space: nowrap;
    width: min-content;
    border: none;
    background-color: var(--green-color);
}

.login-content button:hover {
    transform: scale(1.03);
}

.login-content a {
    color: var(--blue-color);
    text-decoration: underline;
} 

.login-google-button {
    margin: 1.5rem;
}

@media (max-width: 45rem) {
    .login-form {
        grid-template-columns: auto;
        align-items: start;
    }

    .login-content input {
        margin: 0 0.5rem;
    }

    .login-content label {
        margin: 0.5rem 0 0 0;
    }

    .login-content button {
        margin: 1rem 0 0.25rem 0;
    }
}