.eventstats {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
    margin: 0;
    box-sizing: border-box;
}

.eventstats-title {
    text-transform: capitalize;
    font-family: "Trebuchet MS", Raleway, sans-serif;
    font-weight: 1000;
    font-size: clamp(1.2rem, 2.5vw, 1.6rem);
    color: var(--blue-color);
    /* text-decoration: underline double;
    text-underline-offset: 0.35rem; */
    white-space: nowrap;
    margin: 0.5rem 0;
    text-align: start;
    padding: 0 0.5rem;
}

.eventstats-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
}

.eventstats-text-style-1 {
    display: flex;
    flex-direction: column;
    background-color: var(--white-color);
    width: 100%;
    padding: 0;
    box-sizing: border-box;
}

.eventstats-text-style-2 {
    display: flex;
    flex-direction: column;
    background-color: var(--lightblue-color);
    width: 100%;
    padding: 0;
    box-sizing: border-box;
}

.eventstats-user {
    display: grid;
    grid-template-columns: 2rem 8rem 3.5rem 4rem 7.25rem auto;
    width: 100%;
    padding: 0.25rem;
    box-sizing: border-box;
}

.eventstats-text {
    display: flex;
    align-items: center;
    text-align: start;
}

.eventstats-text-title {
    display: flex;
    align-items: center;
    text-align: start;
    color: var(--blue-color);
    font-weight: 600;
}

.eventstats-detail-text {
    display: grid;
    grid-template-columns: 1.5rem 14rem 3.5rem 6.25rem auto;
    width: 100%;
    padding: 0.1rem 0.25rem;
    box-sizing: border-box;
    text-align: start;
}

.eventstats-record-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.eventstats a {
    color: var(--blue-color);
    text-decoration: underline;
} 

@media (max-width: 45rem) {
    .eventstats-text-container {
        width: 100%;
    }

    .eventstats-user {
        grid-template-columns: 1.25rem 4rem 3rem 3.5rem 6rem auto;
    }

    .eventstats-detail-text {
        grid-template-columns: 1.25rem 7.5rem 3rem 6rem auto;
    }
}