.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  max-width: 80rem;
  padding: 0.5rem;
  margin: auto;
  background-color: var(--white-color);
}

a, a:hover, a:visited, a:active {
  color: inherit;
  text-decoration: none;
}