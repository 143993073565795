.upcoming {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 0.5rem;
    background: var(--lightblue-color);
    box-sizing: border-box;
}

.upcoming-title {
    text-transform: capitalize;
    font-family: "Trebuchet MS", Raleway, sans-serif;
    font-weight: 1000;
    font-size: clamp(1.2rem, 2.5vw, 1.6rem);
    color: var(--blue-color);
    /* text-decoration: underline double;
    text-underline-offset: 0.35rem; */
    white-space: nowrap;
}

.upcoming-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.5rem;
    margin: 0.5rem;
    width: auto;
}

.upcoming-text-container p {
    font-family: Raleway, sans-serif;
    text-align: start;
    margin: 0.5rem 0;
    white-space: pre-wrap;
}

.upcoming-img-container {
    display: flex;
    justify-content: center;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
}

.upcoming img {
    max-height: 400px;
    max-width: 100%;
    display: block;
}

.upcoming-button-container {
    display: flex;
    justify-content: center;
    margin: 0.5rem;
    box-sizing: border-box;
}

@media (max-width: 45rem) {
    .upcoming {
        flex-direction: column;
        gap: 0;
    }

    .upcoming-button-container {
        width: 100%;
    }
}