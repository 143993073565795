.activities {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 0.5rem;
    background-color: var(--lightgreen-color);
    padding: 0.75rem 0.35rem;
    box-sizing: border-box;
}

.activities-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.activities-title {
    text-transform: capitalize;
    font-family: "Trebuchet MS", Raleway, sans-serif;
    font-weight: 1000;
    font-size: clamp(1.2rem, 2.5vw, 1.6rem);
    color: var(--blue-color);
    /* text-decoration: underline double;
    text-underline-offset: 0.35rem; */
    white-space: nowrap;
    padding: 0 1rem 0.25rem 0.5rem;
}

.activities-container {
    display: grid;
    justify-content: space-between;
    align-items: start;
    gap: 0;
    width: 100%;
}

.activities-container-0 {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto 1fr;
}

.activities-container-1 {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto 1fr;
}
.activities-container-2 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 1fr;
}
.activities-container-3 {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto 1fr;
}
.activities-container-4 {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto 1fr;
}
.activities-container-5 {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto 1fr;
}
.activities-container-6 {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto 1fr;
}
.activities-container-7 {
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: auto 1fr;
}

.activities-cell {
    grid-column: span(1);
    grid-row: span(1);
    text-align: start;
    padding: 0;
    margin: 0;
    height: 100%;
}

.activities-button-title {
    background: var(--lightergrey-color);
    border: 1px solid var(--lightgrey-color);
    text-align: center;
    font-weight: 600;
    font-size: clamp(1.0rem, 2.5vw, 1.1rem);
    margin: 0.15rem;
    padding: 0.25rem;
    border-radius: 5px;
    width: auto;
}

.activities-button {
    background: var(--lightergrey-color);
    border: 1px solid var(--lightgrey-color);
    margin: 0.15rem;
    padding: 0.25rem;
    border-radius: 5px;
    width: auto;
}

.activities-button-comment {
    background: var(--lightergrey-color);
    border: 1px solid var(--green-color);
    margin: 0.15rem;
    padding: 0.25rem;
    border-radius: 5px;
    width: auto;
    cursor: pointer;
}

.activities-button-link {
    background: var(--white-color);
    border: 1px solid var(--green-color);
    margin: 0.15rem;
    padding: 0.25rem;
    border-radius: 5px;
    width: auto;
}

.activities-11 {
    grid-column: 1;
    grid-row: 1;
}

.activities-12 {
    grid-column: 2;
    grid-row: 1;
}

.activities-13 {
    grid-column: 3;
    grid-row: 1;
}

.activities-14 {
    grid-column: 4;
    grid-row: 1;
}

.activities-15 {
    grid-column: 5;
    grid-row: 1;
}

.activities-16 {
    grid-column: 6;
    grid-row: 1;
}

.activities-17 {
    grid-column: 7;
    grid-row: 1;
}

.activities-21 {
    grid-column: 1;
    grid-row: 2;
}

.activities-22 {
    grid-column: 2;
    grid-row: 2;
}

.activities-23 {
    grid-column: 3;
    grid-row: 2;
}

.activities-24 {
    grid-column: 4;
    grid-row: 2;
}

.activities-25 {
    grid-column: 5;
    grid-row: 2;
}

.activities-26 {
    grid-column: 6;
    grid-row: 2;
}

.activities-27 {
    grid-column: 7;
    grid-row: 2;
}

@media (max-width: 45rem) {
    .activities-container {
        width: 100%;
    }

    .activities-container-0 {
        grid-template-rows: repeat(1, auto);
        grid-template-columns: 1fr;
    }
    .activities-container-1 {
        grid-template-rows: repeat(1, auto);
        grid-template-columns: 1fr 2.5fr;
    }
    .activities-container-2 {
        grid-template-rows: repeat(2, auto);
        grid-template-columns: 1fr 2.5fr;
    }
    .activities-container-3 {
        grid-template-rows: repeat(3, auto);
        grid-template-columns: 1fr 2.5fr;
    }
    .activities-container-4 {
        grid-template-rows: repeat(4, auto);
        grid-template-columns: 1fr 2.5fr;
    }
    .activities-container-5 {
        grid-template-rows: repeat(5, auto);
        grid-template-columns: 1fr 2.5fr;
    }
    .activities-container-6 {
        grid-template-rows: repeat(6, auto);
        grid-template-columns: 1fr 2.5fr;
    }
    .activities-container-7 {
        grid-template-rows: repeat(7, auto);
        grid-template-columns: 1fr 2.5fr;
    }

    .activities-11 {
        grid-column: 1;
        grid-row: 1;
    }
    
    .activities-12 {
        grid-column: 1;
        grid-row: 2;
    }
    
    .activities-13 {
        grid-column: 1;
        grid-row: 3;
    }
    
    .activities-14 {
        grid-column: 1;
        grid-row: 4;
    }
    
    .activities-15 {
        grid-column: 1;
        grid-row: 5;
    }

    .activities-16 {
        grid-column: 1;
        grid-row: 6;
    }
    
    .activities-17 {
        grid-column: 1;
        grid-row: 7;
    }

    .activities-21 {
        grid-column: 2;
        grid-row: 1;
    }
    
    .activities-22 {
        grid-column: 2;
        grid-row: 2;
    }
    
    .activities-23 {
        grid-column: 2;
        grid-row: 3;
    }
    
    .activities-24 {
        grid-column: 2;
        grid-row: 4;
    }
    
    .activities-25 {
        grid-column: 2;
        grid-row: 5;
    }

    .activities-26 {
        grid-column: 2;
        grid-row: 6;
    }
    
    .activities-27 {
        grid-column: 2;
        grid-row: 7;
    }

}