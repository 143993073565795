.upcomingevents_contact-container {
    display: grid;
    grid-template-columns: 100%;
    margin: 0.75rem 0.5rem;
}

.upcomingevents_contact-activity {
    text-align: start;
    font-weight: 500;
    border-spacing: 0;
}

.upcomingevents_contact-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-evenly;
    align-items: start;
    gap: 0.35rem;
    width: 100%;
}