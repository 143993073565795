.leaders {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 0.5rem;
    background-color: var(--white-color);
    padding: 0.75rem 0.5rem;
    box-sizing: border-box;
}

.leaders-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.leaders-title {
    text-transform: capitalize;
    font-family: "Trebuchet MS", Raleway, sans-serif;
    font-weight: 1000;
    font-size: clamp(1.2rem, 2.5vw, 1.6rem);
    color: var(--blue-color);
    /* text-decoration: underline double;
    text-underline-offset: 0.35rem; */
    white-space: nowrap;
    padding: 0 1rem 0.25rem 0.35rem;
}

.leaders-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    justify-content: space-between;
    align-items: start;
    gap: 0.35rem;
    width: 100%;
}

.leaders-cell {
    grid-column: span(1);
    grid-row: span(1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: start;
    padding: 0;
    margin: 0;
    height: 100%;
    border-radius: 5px;
    border: 1px solid var(--green-color);
    background: var(--lightgreen-color);
}

.leaders-cell-more {
    justify-content: center;
}

.leaders-button-title {
    border: 0;
    text-align: center;
    font-weight: 500;
    /* font-size: clamp(1.0rem, 2.5vw, 1.1rem); */
    margin: 0.15rem;
    padding: 0.25rem;
    width: auto;
}

.leaders-button {
    border: 0;
    margin: 0.35rem 0.35rem 0 0.35rem;
    padding: 0;
    width: auto;
}

.leaders-button img {
    max-width: 100%;
    height: auto;
    border-radius: 50%;
    cursor: pointer;
}

.leaders-button img:hover {
    transform: scale(0.97);
}

@media (max-width: 45rem) {
    .leaders-container {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        width: 100%;
    }

}