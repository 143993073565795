.button {
    cursor: pointer;
    margin: 0.1rem;
    padding: 0.25rem 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-family: Raleway, sans-serif;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: clamp(1.1rem, 2vw, 1.3rem);
    width: min-content;
    white-space: nowrap;
}

.button:hover {
    transform: scale(1.03);
}

.greenButton {
    background: var(--green-color);
    color: var(--black-color);
    border: 1px solid var(--green-color);
}

.blueButton {
    background: var(--blue-color);
    color: var(--white-color);
    border: 1px solid var(--blue-color);
}

.whiteButton {
    background: var(--white-color);
    color: var(--blue-color);
    border: 1px solid var(--blue-color);
}

.blankButton {
    background: var(--transparent-color);
    color: var(--black-color);
    border: 0px;
    padding: 0.1rem;
}

.bigBlueStaticButton {
    background: var(--blue-color);
    color: var(--white-color);
    border: 1px solid var(--blue-color);
    padding: 0.75rem 1rem;
    cursor: default;
    width: auto;
}

.bigBlueStaticButton:hover {
    transform: scale(1);
}

.smallButton {
    background: var(--grey-color);
    color: var(--white-color);
    /* border: 1px solid var(--blue-color); */
    margin: 0.1rem;
    padding: 0.1rem 0.5rem;
    font-weight: 500;
    font-size: clamp(0.9rem, 2vw, 1.1rem);
    cursor: default;
}

.smallButton:hover {
    transform: scale(1);
}