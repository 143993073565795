.servicehour {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
    margin: 0;
    box-sizing: border-box;
}

.servicehour-title {
    text-transform: capitalize;
    font-family: "Trebuchet MS", Raleway, sans-serif;
    font-weight: 1000;
    font-size: clamp(1.2rem, 2.5vw, 1.6rem);
    color: var(--blue-color);
    /* text-decoration: underline double;
    text-underline-offset: 0.35rem; */
    white-space: nowrap;
    margin: 0.5rem 0;
    text-align: start;
    padding: 0 0.5rem;
}

.servicehour-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.servicehour-text-style-1 {
    display: flex;
    flex-direction: column;
    background-color: var(--white-color);
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    gap: 0.5rem;
}

.servicehour-text-style-2 {
    display: flex;
    flex-direction: column;
    background-color: var(--lightblue-color);
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    gap: 0.5rem;
}

.servicehour-event {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    gap: 0.5rem;
}

.servicehour-text {
    width: 100%;
    text-align: start;
    vertical-align: center;
}

.servicehour-text-title {
    text-align: start;
    margin: 0.5rem;
    color: var(--blue-color);
    font-weight: 600;
}

.servicehour-text p {
    text-align: start;
    margin: 0.5rem;
    white-space: pre-wrap;
}

.servicehour-record {
    display: grid;
    grid-template-columns: auto auto;
    text-align: start;
    width: 70%;
    padding: 0.25rem 0.25rem 0.25rem 0.5rem;
    box-sizing: border-box;
}

.servicehour-record-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.servicehour input, select {
    padding: 0.25rem;
    margin: 0;
    border-radius: 5px;
    border: 1px solid gray;
    font-family: Raleway, sans-serif;
}

.servicehour input {
    max-width: 3rem;
}

.servicehour a {
    color: var(--blue-color);
    text-decoration: underline;
} 

.servicehour-button {
    width: auto;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5rem;
}

.servicehour-activity-container {
    display: grid;
    grid-template-columns: 70% 30%;
    margin: 0.75rem 0.5rem;
}

.servicehour-activity {
    text-align: start;
}

.servicehour-activity-info {
    font-weight: 600;
}

.servicehour-activity-person {
    padding: 0 0 0 0.5rem;
    color: var(--blue-color);
}

@media (max-width: 45rem) {
    .servicehour-record {
        width: 100%;
    }
}