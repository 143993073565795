.upcomingevents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 0.5rem;
    margin: 0;
    box-sizing: border-box;
}

.upcomingevents-title {
    text-transform: capitalize;
    font-family: "Trebuchet MS", Raleway, sans-serif;
    font-weight: 1000;
    font-size: clamp(1.2rem, 2.5vw, 1.6rem);
    color: var(--blue-color);
    /* text-decoration: underline double;
    text-underline-offset: 0.35rem; */
    white-space: nowrap;
    margin: 0.5rem 0;
    text-align: start;
    padding: 0 0.5rem;
}

.upcomingevents-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.upcomingevents-text-style-1 {
    display: flex;
    flex-direction: row;
    background-color: var(--white-color);
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    gap: 0.5rem;
}

.upcomingevents-text-style-2 {
    display: flex;
    flex-direction: row;
    background-color: var(--lightblue-color);
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    gap: 0.5rem;
}

.upcomingevents-more {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
}

.upcomingevents-text {
    width: 33%;
    word-wrap: break-word;
}

.upcomingevents-link {
    text-decoration: underline;
}

.upcomingevents-text-title {
    text-align: start;
    margin: 0.5rem;
    color: var(--blue-color);
    font-weight: 600;
}

.upcomingevents-text p {
    text-align: start;
    margin: 0.5rem;
    white-space: pre-wrap;
}

.upcomingevents-activity-container {
    display: grid;
    grid-template-columns: 70% 30%;
    margin: 0.75rem 0.5rem;
}

.upcomingevents-activity {
    text-align: start;
}

.upcomingevents-activity-info {
    font-weight: 500;
}

.upcomingevents-activity-person {
    padding: 0 0 0 0.5rem;
    color: var(--blue-color);
}

.upcomingevents-button {
    display: flex;
    padding: 0;
    justify-content: flex-end;
    align-items: flex-start;
}

.upcomingevents-slider {
    width: 34%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.upcomingevents-slider img {
    max-height: 500px;
    max-width: 100%;
    height: auto;
    display: block;
    object-fit: contain;
}

.upcomingevents-blank {
    height: 0.25rem;
    display: none;
}

@media (max-width: 45rem) {
    .upcomingevents-text-style-1,
    .upcomingevents-text-style-2 {
        flex-direction: column;
    }

    .upcomingevents-text,
    .upcomingevents-slider {
        width: 100%;
    }

    .upcomingevents-slider {
        justify-content: center;
    }

    .upcomingevents-blank {
        display: flex;
    }
}