.artgallery {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 0.5rem;
    margin: 0;
    box-sizing: border-box;
}

.artgallery a {
    color: var(--blue-color);
    text-decoration: underline;
} 

.artgallery-title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.75rem;
    background-color: var(--lightgreen-color);
    width: 100%;
}

.artgallery-title {
    text-transform: capitalize;
    font-family: "Trebuchet MS", Raleway, sans-serif;
    font-weight: 1000;
    font-size: clamp(1.2rem, 2.5vw, 1.6rem);
    color: var(--blue-color);
    /* text-decoration: underline double;
    text-underline-offset: 0.35rem; */
    white-space: nowrap;
    margin: 0.5rem 0;
    text-align: start;
    padding: 0 0.5rem;
}

.artgallery-description {
    text-align: start;
    padding: 0.5rem;
}

.artgallery-content-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* grid-template-rows: 1fr 1fr; */
    justify-content: space-between;
    align-items: flex-end;
    gap: 1.25rem;
    width: 100%;
}

.artgallery-content {
    padding: 0rem;
}

.artgallery-content-art {
    display: flex;
    flex-direction: row;
    background-color: var(--lightergrey-color);
    width: 100%;
    padding: 3rem;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

.artgallery-content-art img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.artgallery-content-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: flex-start;

}

.artgallery-content-text p {
    font-family: Raleway, sans-serif;
    text-align: start;
    margin: 0.5rem;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
}


@media (max-width: 45rem) {
    .artgallery-content-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .artgallery-content-art {
        padding: 2.25rem;
    }
}