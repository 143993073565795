.contact {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    box-sizing: border-box;
    background: var(--white-color);
    font-family: Raleway, sans-serif;
    gap: 1rem;
    margin: 0.5rem 0;
}

.contact_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.contact_container p {
    text-align: start;
    line-height: 150%;
}

.contact_container img {
    max-width: 100%;
    height: auto;
}

.email {
    color: var(--blue-color--);
    text-decoration: underline;
}

.contact_title {
    justify-content: start;
    align-items: start;
    text-align: start;
    text-transform: capitalize;
    font-family: "Trebuchet MS", Raleway, sans-serif;
    font-weight: 1000;
    font-size: clamp(1.2rem, 2.5vw, 1.6rem);
    color: var(--blue-color);
    /* text-decoration: underline double;
    text-underline-offset: 0.35rem; */
}

.contact-form {
    display: grid;
    grid-template-columns: 5rem 16rem auto;
    width: 100%;
    align-items: center;
}

.contact input, 
.contact textarea {
    padding: 0.5rem;
    margin: 0.25rem;
    border-radius: 5px;
    border: 1px solid gray;
    font-family: Raleway, sans-serif;
}

.contact label {
    font-weight: 600;
    text-align: start;
    padding: 0.5rem 0;
    margin: 0.5rem 0;
}

.contact span {
    padding: 0.5rem;
    color: var(--darkgrey-color);
    font-size: clamp(0.8rem, 2vw, 0.9rem);
    text-align: start;
}

.contact button {
    cursor: pointer;
    margin: 0.5rem;
    padding: 0.25rem 0.75rem;
    color: var(--black-color);
    border-radius: 5px;
    font-family: Raleway, sans-serif;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: clamp(1.1rem, 2.5vw, 1.4rem);
    width: min-content;
    border: none;
    background-color: var(--green-color);
}

.contact button:hover {
    transform: scale(1.03);
}

@media (max-width: 45rem) {
    .contact {
        flex-direction: column;
        align-items: center;
    }

    .contact_container p {
        line-height: 125%;
    }

    .contact-form {
        grid-template-columns: auto;
        align-items: start;
    }

    .contact input, 
    .contact textarea {
        margin: 0 0;
    }

    .contact label {
        margin: 0.5rem 0 0 0;
    }

    .contact span {
        padding: 0.25rem 0;
    }

}