.event_records {
    font-family: Raleway, sans-serif;
    width: 100%;
}

.event_records-title {
    text-transform: capitalize;
    font-family: "Trebuchet MS", Raleway, sans-serif;
    font-weight: 1000;
    font-size: clamp(1.2rem, 2.5vw, 1.6rem);
    color: var(--blue-color);
    /* text-decoration: underline double;
    text-underline-offset: 0.35rem; */
    padding: 0.5rem 0 1rem 0;
}

.event_records-content {
    text-align: start;
    margin: 0.5rem 0.5rem 1rem 0.5rem;
}

.formInput{
    display: grid;
    grid-template-columns: 7rem auto 25rem;
    width: 100%;
    align-items: center;
}

.event_records input, select, textarea {
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 5px;
    border: 1px solid gray;
    font-family: Raleway, sans-serif;
}

.event_records label {
    font-weight: 600;
    text-align: start;
    padding: 0.5rem;
    margin: 0.5rem 0;
}

.event_records span {
    padding: 0.5rem;
    color: var(--darkgrey-color);
    font-size: clamp(0.8rem, 2vw, 0.9rem);
    text-align: start;
}

.event_records button {
    cursor: pointer;
    margin: 0.5rem;
    padding: 0.5rem 0.75rem;
    border-radius: 5px;
    font-family: Raleway, sans-serif;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: clamp(1.1rem, 2.5vw, 1.4rem);
    width: min-content;
    border: none;
    background-color: var(--green-color);
}

.event_records button:hover {
    transform: scale(1.03);
}

.event_records a {
    color: var(--blue-color);
    text-decoration: underline;
} 

.event_records-photolist {
    display: grid;
    grid-template-columns: 12rem 20rem 6rem;
    align-items: center;
}

@media (max-width: 45rem) {
    .formInput {
        grid-template-columns: auto;
        align-items: start;
    }

    .event_records input, select, textarea {
        margin: 0 0.5rem;
    }

    .event_records label {
        margin: 0.5rem 0 0 0;
    }
}