.specialolympicsreg {
    font-family: Raleway, sans-serif;
    margin: 0 0 0.75rem 0;
    width: 100%;
}

.specialolympicsreg-title {
    text-transform: capitalize;
    font-family: "Trebuchet MS", Raleway, sans-serif;
    font-weight: 1000;
    font-size: clamp(1.2rem, 2.5vw, 1.6rem);
    color: var(--blue-color);
    /* text-decoration: underline double;
    text-underline-offset: 0.35rem; */
    padding: 0.5rem 0 1rem 0;
}

.specialolympicsreg-content {
    text-align: start;
    margin: 0.5rem 0.5rem 1rem 0.5rem;
}

.specialolympicsreg-form {
    display: grid;
    grid-template-columns: 7rem 13rem auto;
    width: 100%;
    align-items: center;
}

.specialolympicsreg-form2 {
    display: grid;
    grid-template-columns: 10rem auto;
    width: 100%;
    align-items: center;
}

.specialolympicsreg-form3 {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0.5rem;
    margin: 0.5rem 0;
}

.specialolympicsreg input, 
.specialolympicsreg select,
.specialolympicsreg textarea {
    padding: 0.5rem;
    margin: 0.25rem;
    border-radius: 5px;
    border: 1px solid gray;
    font-family: Raleway, sans-serif;
}

.specialolympicsreg p {
    padding: 0.5rem 0;
    margin: 0.5rem 0;
    text-align: start;    
}

.specialolympicsreg-form p {
    padding: 0.5rem;
}

.specialolympicsreg label {
    font-weight: 600;
    text-align: start;
    padding: 0.5rem;
    margin: 0.5rem 0;
}

.specialolympicsreg span {
    padding: 0.5rem;
    color: var(--darkgrey-color);
    font-size: clamp(0.8rem, 2vw, 0.9rem);
    text-align: start;
}

.specialolympicsreg button {
    cursor: pointer;
    margin: 0.5rem;
    padding: 0.25rem 0.75rem;
    color: var(--black-color);
    border-radius: 5px;
    font-family: Raleway, sans-serif;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: clamp(1.1rem, 2.5vw, 1.4rem);
    width: min-content;
    border: none;
    background-color: var(--green-color);
}

.specialolympicsreg button:hover {
    transform: scale(1.03);
}

.specialolympicsreg a {
    color: var(--blue-color);
    text-decoration: underline;
} 


@media (max-width: 45rem) {
    .specialolympicsreg-form {
        grid-template-columns: auto;
        align-items: start;
    }

    .specialolympicsreg-form2 {
        grid-template-columns: auto;
        align-items: start;
    }

    .specialolympicsreg-form3 {
        align-items: start;
    }

    .specialolympicsreg input, 
    .specialolympicsreg select,
    .specialolympicsreg textarea {
        margin: 0 0.5rem;
    }

    .specialolympicsreg label {
        margin: 0.5rem 0 0 0;
    }
}