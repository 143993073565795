.navbar_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    background: var(--white-color);
    z-index: 10;
}

@supports (position: sticky) {
    .navbar_container {
        position: sticky;
        top: 0;
    }
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    position: relative;
}


.navbar-logo img {
    width: 14rem;
}

.navbar-links-container {
    display: flex;
    justify-content: space-evenly;
}

.navbar-links-item {
    margin: 0.1rem 0.5rem;
    display: flex;
    align-items: center;
    font-family: Raleway, sans-serif;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: clamp(1.1rem, 2.5vw, 1.3rem);
}

.navbar-links-subitem {
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.navbar-links-subitem p {
    margin: 0;
}

.navbar-dropdown {
    margin: 0 0.25rem;
    display: flex;
    align-items: center;
    position: relative;
}

.navbar-dropdown-link {
    cursor: pointer;
    margin: 0.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-family: Raleway, sans-serif;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: clamp(1.1rem, 2vw, 1.3rem);
    background: var(--transparent-color);
    color: var(--black-color);
    border: 0px;
    padding: 0.1rem;
}

.navbar-dropdown-link:hover {
    transform: scale(1.03);
}

.navbar-dropdown-menu {
    position: absolute;
    left: 0;
    top: calc(100% + 0.25rem);
    background: var(--white-color-semitransparent); 
    text-align: left;
    padding: 0.2rem;
    border-radius: 5px;
    box-shadow: 3px 3px 5px 1px rgba(0,0,0,0.2);
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    width: max-content;
}

.navbar-dropdown-menu p {
    font-family: Raleway, sans-serif;
    text-align: start;
    font-size: clamp(0.9rem, 2.5vw, 1.2rem);
    font-weight: 600;
    margin: 0.1rem;
}

.navbar-dropdown.active > .navbar-dropdown-link ~ .navbar-dropdown-menu {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}

/* .navbar-login {
    display: flex;
    flex-direction: column;
    font-family: Raleway, sans-serif;
    font-size: clamp(0.9rem, 2.5vw, 1.2rem);
    font-weight: 600;
    margin: 0.4rem;
    gap: 0.3rem;
} */

.navbar-menu {
    display: none;
}

.navbar-menu svg {
    cursor: pointer;
}

.navbar-menu-ri {
    color: var(--green-color);
}

.navbar-menu-toggle-ri {
    color: var(--black-color);
    background: var(--white-color);
}

@media (max-width: 60rem) {
    .navbar-dropdown {
        margin: 0;
    }

    .navbar-logo img {
        width: 13rem;
    }
}

@media (max-width: 45rem) {
    .navbar-links-container {
        display: none;
    }

    .navbar-menu {
        display: flex;
    }

    .navbar-logo img {
        width: 12rem;
    }
}

