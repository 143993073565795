.event_signup_mod {
    font-family: Raleway, sans-serif;
    width: 100%;
}

.event_signup_mod-title {
    text-transform: capitalize;
    font-family: "Trebuchet MS", Raleway, sans-serif;
    font-weight: 1000;
    font-size: clamp(1.2rem, 2.5vw, 1.6rem);
    color: var(--blue-color);
    /* text-decoration: underline double;
    text-underline-offset: 0.35rem; */
    padding: 0.5rem 0 1rem 0;
}

.event_signup_mod-content {
    text-align: start;
    margin: 0.5rem 0.5rem 1rem 0.5rem;
}

.event_signup_mod-formInput{
    display: grid;
    grid-template-columns: 7rem auto 25rem;
    width: 100%;
    align-items: center;
}

.event_signup_mod-formInput input, 
.event_signup_mod-formInput select, 
.event_signup_mod-formInput textarea {
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 5px;
    border: 1px solid gray;
    font-family: Raleway, sans-serif;
}

.event_signup_mod label {
    font-weight: 600;
    text-align: start;
    padding: 0.5rem;
    margin: 0.5rem 0;
}

.event_signup_mod span {
    padding: 0.5rem;
    color: var(--darkgrey-color);
    font-size: clamp(0.8rem, 2vw, 0.9rem);
    text-align: start;
}

.event_signup_mod button {
    cursor: pointer;
    margin: 0.5rem;
    padding: 0.5rem 0.75rem;
    border-radius: 5px;
    font-family: Raleway, sans-serif;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: clamp(1.1rem, 2.5vw, 1.4rem);
    width: min-content;
    border: none;
    background-color: var(--green-color);
}

.event_signup_mod button:hover {
    transform: scale(1.03);
}

.event_signup_mod a {
    color: var(--blue-color);
    text-decoration: underline;
} 

@media (max-width: 45rem) {
    .event_signup_mod-formInput {
        grid-template-columns: auto;
        align-items: start;
    }

    .radioInput {
        flex-direction: column;
    }

    .event_signup_mod input, select, textarea {
        margin: 0 0.5rem;
    }

    .event_signup_mod label {
        margin: 0.5rem 0 0 0;
    }
}




.upcomingevents_mod {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 0.5rem;
    margin: 0;
    box-sizing: border-box;
}

.upcomingevents_mod-title {
    text-transform: capitalize;
    font-family: "Trebuchet MS", Raleway, sans-serif;
    font-weight: 1000;
    font-size: clamp(1.2rem, 2.5vw, 1.6rem);
    color: var(--blue-color);
    /* text-decoration: underline double;
    text-underline-offset: 0.35rem; */
    white-space: nowrap;
    margin: 0.5rem 0;
    text-align: start;
    padding: 0 0.5rem;
}

.upcomingevents_mod-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.upcomingevents_mod-text-style-1 {
    display: flex;
    flex-direction: row;
    background-color: var(--white-color);
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    gap: 0.5rem;
}

.upcomingevents_mod-text-style-2 {
    display: flex;
    flex-direction: row;
    background-color: var(--lightblue-color);
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    gap: 0.5rem;
}

.upcomingevents_mod-more {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
}

.upcomingevents_mod-text {
    width: 40%;
}

.upcomingevents_mod-link {
    text-decoration: underline;
}

.upcomingevents_mod-text-title {
    text-align: start;
    margin: 0.5rem;
    color: var(--blue-color);
    font-weight: 600;
}

.upcomingevents_mod-text p {
    text-align: start;
    margin: 0.5rem;
    white-space: pre-wrap;
}

.upcomingevents_mod-activity-container {
    display: grid;
    grid-template-columns: 70% 30%;
    margin: 0.75rem 0.5rem;
}

.upcomingevents_mod-activity {
    text-align: start;
}

.upcomingevents_mod-activity-info {
    font-weight: 500;
}

.upcomingevents_mod-activity-person {
    padding: 0 0 0 0.5rem;
    color: var(--blue-color);
}

.upcomingevents_mod-button {
    display: flex;
    padding: 0;
    justify-content: flex-end;
    align-items: flex-start;
}

.upcomingevents_mod-slider {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upcomingevents_mod-slider img {
    max-height: 500px;
    max-width: 100%;
    height: auto;
    display: block;
    object-fit: contain;
}

.upcomingevents_mod-blank {
    height: 0.5rem;
    display: none;
}

@media (max-width: 45rem) {
    .upcomingevents_mod-text-style-1,
    .upcomingevents_mod-text-style-2 {
        flex-direction: column;
    }

    .upcomingevents_mod-text,
    .upcomingevents_mod-slider {
        width: 100%;
    }

    .upcomingevents_mod-slider {
        justify-content: center;
    }

    .upcomingevents_mod-blank {
        display: flex;
    }
}